import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import LoginPage from './pages/LoginPage';
import EventsListingPage from './pages/EventsListingPage';

function App() {
  return (
      <Router>
        <Routes>
          {/* Redirect root path to login */}
          <Route path="/" element={<Navigate to="/login" replace />} />

          {/* Login page route */}
          <Route path="/login" element={<LoginPage />} />

          {/* Events listing page route */}
          <Route path="/events" element={<EventsListingPage />} />
        </Routes>
      </Router>
  );
}

export default App;
