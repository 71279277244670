import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import '../styles/EventsListingPage.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function EventsListingPage() {
    const navigate = useNavigate();

    const handleLogout = () => {
        navigate('/login');
    };

    const categories = [
        { title: "Now Trending", events: trendingEvents },
        { title: "Medicine", events: medicineEvents },
        { title: "Entrepreneurship", events: entrepreneurshipEvents },
    ];

    return (
        <div className="events-container">
            {/* Header with Logout */}
            <header className="events-header">
                <h1>Events</h1>
                <button className="logout-button" onClick={handleLogout}>
                    Logout
                </button>
            </header>

            {/* Event Categories */}
            <main className="events-content">
                {categories.map((category, index) => (
                    <CategorySection key={index} category={category} />
                ))}
            </main>

            {/* Footer */}
            <footer className="events-footer">
                <p>&copy; 2025 All Rights Reserved</p>
                <p>About | Contact Us | Privacy and Terms</p>
            </footer>
        </div>
    );
}

const CategorySection = ({ category }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768, // Mobile breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="category-section">
            <h2>{category.title}</h2>
            <Slider {...settings}>
                {category.events.map((event, index) => (
                    <EventCard key={index} event={event} />
                ))}
            </Slider>
        </section>
    );
};

const EventCard = ({ event }) => (
    <div className="event-card">
        <img src={event.image} alt={event.title} className="event-image" />
        <div className="event-details">
            <h3>{event.title}</h3>
            <p>{event.date}</p>
            <p>{event.location}</p>
        </div>
    </div>
);

// Sample Event Data
const trendingEvents = [
    { title: "MAKE MONEY CONFERENCE", date: "Nov 26, 10:00 PM IST", location: "Barcelona, Spain", image: "/images/event1.jpg" },
    { title: "Databricks Data Science Summit", date: "Oct 21, 9:00 AM PST", location: "San Francisco, CA", image: "/images/event2.jpg" },
    { title: "Electronic Music Workshop", date: "Dec 12, 1:30 PM EST", location: "Boston, MA", image: "/images/event3.jpg" },
];

const medicineEvents = [
    { title: "ACS NLCRT Annual Meeting", date: "Dec 9, 7:00 AM EST", location: "Atlanta, GA", image: "/images/event4.jpg" },
];

const entrepreneurshipEvents = [
    { title: "Wolf Den Pitch Competition", date: "Nov 25, 5:00 PM EST", location: "Raleigh, NC", image: "/images/event5.jpg" },
];

export default EventsListingPage;
