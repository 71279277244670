import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginPage.css';
import microsoftLogo from '../assets/loginPage/microsoft_logo.svg';
import googleLogo from '../assets/loginPage/google_logo.svg';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleLogin = (event) => {
        event.preventDefault();
        if (email === 'admin@getafterhours.com' && password === 'Afterhours@$1234567890') {
            navigate('/events'); // Redirect to EventsListingPage
        } else {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <img src="logo.png" alt="Logo" className="login-logo" />
                <h2>Sign in</h2>
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        placeholder="Email"
                        required
                        className="input-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="password-container">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="Password"
                            required
                            className="input-field"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            type="button"
                            className="show-password"
                            onClick={togglePasswordVisibility}
                        >
                            {passwordVisible ? 'hide' : 'show'}
                        </button>
                    </div>
                    {error && <p className="error-message">{error}</p>}
                    <a href="/" className="forgot-password">Forgot password?</a>
                    <button type="submit" className="signin-button">Sign in</button>
                    <hr />
                    <button type="button" className="google-signin">
                        <img src={googleLogo} alt="Google" className="google-icon" /> Sign in with Google
                    </button>
                    <button type="button" className="microsoft-signin">
                        <img src={microsoftLogo} alt="Microsoft" className="microsoft-icon" /> Sign in with Microsoft
                    </button>
                    <button type="button" className="signup-button">
                        New User? Create an Account!
                    </button>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;
